import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowRight } from "@fortawesome/free-solid-svg-icons"
import { useTranslation } from "gatsby-plugin-react-i18next"
import { GalleryItem } from "./portfolioCardStyled"

const PortfolioCard = ({ image, title, link, techno, type, linkDisable }) => {

  const { t } = useTranslation()
  const images = useStaticQuery(graphql`
      query {
          projects: allFile(filter: {relativeDirectory: {eq: "portfolio"}}) {
              edges {
                  node {
                      childImageSharp {
                          fluid(quality: 90, maxWidth: 1920) {
                              ...GatsbyImageSharpFluid_withWebp,
                              originalName
                          }
                      }
                  }
              }
          }
      }
  `)

  const project = images.projects.edges
    .find(project => project.node.childImageSharp.fluid.originalName === image)
  const imageAlt = t("portfolio.alt.image") + " " + title

  return (
    <GalleryItem className="glry-item">
      <div className="portfolio-inner">
        <h3 className="portfolio-title">{title}</h3>
        <div className="portfolio-back">
          {
            linkDisable ? <></> :
              <div className="portfolio-buttons">
                <a href={link} target="_blank" rel="noopener noreferrer" aria-label={title}>
                  <FontAwesomeIcon icon={faArrowRight}/>
                </a>
              </div>
          }

        </div>
        <Img fluid={project.node.childImageSharp.fluid}
             alt={imageAlt}
             title={imageAlt}
             className="img-fluid portfolio-img"
             loading={"lazy"}/>
          {
            linkDisable ? <></> :
              <div className="project-description">
                <p>{t("portfolio.type.site") + " " + type}</p>
                <p>{techno.join(", ")}</p>
              </div>
          }
      </div>
    </GalleryItem>
  )
}

export default PortfolioCard

