import styled from "styled-components"
import { mediaQueries } from "../../style/mediaQueries"

export const GalleryItem = styled.div`
  border-radius: 5px;

  &:hover {
    .portfolio-back .portfolio-buttons {
      opacity: 1;
      display: block;
      visibility: visible;
      transition-delay: 0.3s;
      animation: fadeInUp 0.3s ease-in-out forwards;
    }
  }

  &:hover .portfolio-back::after, &:hover .portfolio-back::before {
    top: 0;
    transition: all 0.5s;
  }

  .portfolio-inner {
    overflow: hidden;
    position: relative;
    border-radius: 5px;
    box-shadow: 0 1rem 2.8rem 0 ${props => props.theme.color.shadow};

    .project-description {
      z-index: 71;
      width: 100%;
      bottom: -15rem;
      padding: 1rem 0rem;
      text-align: center;
      position: absolute;
      background: ${props => props.theme.color.white};
      transition: all 0.5s ease;
      transform-origin: 0 100%;
    }

    .portfolio-title {
      width: 100%;
      z-index: 71;
      top: -15rem;
      position: absolute;
      text-align: center;
      color: ${props => props.theme.color.white};
      transition: all 0.5s ease;
      transform-origin: 0 100%;
      font-size: 1.95rem;
      @media only screen and (${mediaQueries.tabLand}) {
        font-size: 1.7rem;
      }
    }
  }

  &:hover .project-description {
    bottom: 0;
  }

  &:hover .portfolio-title {
    top: 5rem;
  }

  .portfolio-back {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    text-align: center;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    transition: all 0.4s;
    z-index: 70;

    &:after, &:before {
      content: "";
      width: 50%;
      height: 100%;
      opacity: 0.6;
      position: absolute;
      background: ${props => props.theme.color.backgroundDark};
      transition: all 0.5s;
      transition-delay: 0.25s;
    }

    &:after {
      left: 0;
      top: -100%;
    }

    &:before {
      right: 0;
      top: 100%;
    }

    .portfolio-buttons {
      z-index: 73;
      animation: fadeOutDown 0.3s ease-in-out forwards;

      a {
        font-size: 2rem;
        height: 5.6rem;
        width: 5.6rem;
        line-height: 5.6rem;
        border-radius: 5px;
        text-align: center;
        margin-right: 1rem;
        display: inline-block;
        background: ${props => props.theme.color.white};
        color: ${props => props.theme.color.primary};
        border: 2px solid transparent;
        transition: all 0.25s ease;

        &:hover {
          color: ${props => props.theme.color.white};
          background: transparent;
          border-color: ${props => props.theme.color.white};
          animation: pulseWhite 1s 1 linear forwards;
          transform: translateY(-5px);
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
`