import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/hero"
import About from "../components/about"
import Services from "../components/services"
import Portfolio from "../components/portfolio"
import Banner from "../components/banner"
import Client from "../components/client"
import Contact from "../components/contact"
import Commitment from "../components/commitment"
import { useTranslation } from "gatsby-plugin-react-i18next"
import TopScroll from "../components/button/TopScroll"
import styled from "styled-components"
import { mediaQueries } from "../style/mediaQueries"

const LayoutStyled = styled(Layout)`
  grid-row-gap: 15rem;
  grid-template-rows: calc(var(--vh, 1vh) * 100) repeat(8, min-content);

  @media only screen and (${mediaQueries.tabPort}) {
    grid-row-gap: 6rem;
  }
`

const IndexPage = () => {
  const { t } = useTranslation()
  return (
    <LayoutStyled>
      <SEO title={t("seo.title")} />
      <TopScroll target={"hero-section"} />
      <Hero />
      <About />
      <Services />
      <Commitment />
      <Banner />
      <Portfolio />
      <Client />
      <Contact title={t("contact.title")}
               prefixTitle={t("contact.prefixTitle")}
               withBackground={false} />
    </LayoutStyled>
  )
}

export default IndexPage
