import React from "react"
import SectionTitle from "./sectionTitle"
import PortfolioCard from "./portfolioCard/portfolioCard"
import { useTranslation } from "gatsby-plugin-react-i18next"
import styled from "styled-components"
import { mediaQueries } from "../style/mediaQueries"

const SectionStyled = styled.section`
  grid-column: center-start / center-end;
`

const GalleryStyled = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  
  @media only screen and (${mediaQueries.tabLand}) {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  }
  
  @media only screen and (${mediaQueries.tabPort}) {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }
  grid-gap: 3.1rem;
`

const Portfolio = () => {
  const { t } = useTranslation()

  const portfolioItems = [
    {
      id: 1,
      image: "portfolio4.jpg",
      title: "IOL Translation",
      link: "https://www.iol-translation.com/",
      techno: ["Gatsby Js"],
      type: t("portfolio.type.vitrine"),
      linkDisable: false
    },
    {
      id: 2,
      image: "portfolio1.jpg",
      title: "BCER",
      link: "https://bcer-site.bcerbureau.now.sh/",
      techno: ["NextJs", "Firebase"],
      type: t("portfolio.type.vitrine.and.blog"),
      linkDisable: false
    },
    {
      id: 3,
      image: "portfolio2.jpg",
      title: "Sara Salmi traductrice",
      link: "https://www.lesbonheursdelatraduction.com/",
      techno: ["Gatsby Js"],
      type: t("portfolio.type.vitrine"),
      linkDisable: false
    }
  ]

  const portfolioItemRender = portfolioItems.map((data) => (
    <PortfolioCard key={data.id} {...data}></PortfolioCard>
  ))

  return (
    <SectionStyled>
      <SectionTitle title={t("portfolio.title")}
                    subTitle={t("portfolio.subTitle")} />
      <GalleryStyled>{portfolioItemRender}</GalleryStyled>
    </SectionStyled>
  )
}

export default Portfolio