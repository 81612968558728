import React from "react"
import { useTranslation } from "gatsby-plugin-react-i18next"
import ScrollTo from "../api/scrollTo"
import styled from "styled-components"
import { mediaQueries } from "../style/mediaQueries"

const Section = styled.section`
  grid-column: full-start / full-end;
  background: ${props => props.theme.color.primary};
  display: flex;
  align-items: center;
  justify-content: space-around;
  min-height: 26rem;
  @media only screen and (${mediaQueries.phoneLand}) {
    min-height: 16rem;
  }
`

const Question = styled.h2`
  color: ${props => props.theme.color.white};
  font-size: 3.4rem;
  text-transform: inherit;

  @media only screen and (${mediaQueries.phonePort}) {
    font-size: 1.5rem;
  }
`

const Banner = () => {
  const { t } = useTranslation()

  return (
    <Section>
      <div>
        <Question>{t("banner.text.1")}</Question>
        <Question>{t("banner.text.2")}</Question>
      </div>
      <ScrollTo id="banner-contact-us" target={"contact"}
                className={"banner-button outline-button"}>
        {t("common.btn.contacter")}
      </ScrollTo>
    </Section>
  )
}

export default Banner