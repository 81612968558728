import React from "react"
import {
  faLaptop,
  faRetweet,
  faRulerCombined,
  faSearch,
  faHandsHelping,
  faGlobe
} from "@fortawesome/free-solid-svg-icons"
import { library, findIconDefinition } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import styled from "styled-components"
import { mediaQueries } from "../style/mediaQueries"
library.add(faLaptop, faRetweet, faRulerCombined, faGlobe, faSearch, faHandsHelping)

const BoxStyled = styled.div`
  border-radius: 5px;
  padding: 30px;
  transition: all 0.25s linear;
  box-shadow: 0 10px 28px 0 ${props => props.theme.color.shadow};

  &:hover {
    box-shadow: 0 23px 49px 0 ${props => props.theme.color.shadow};
  }
`

const BoxBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const IconStyled = styled.div`
  display: inline-block;
  height: 8rem;
  width: 8rem;
  text-align: center;
  font-size: 4rem;
  line-height: 8rem;
  border-radius: 5px;
  background: ${props => props.theme.color.primary};
  color: ${props => props.theme.color.white};
  margin-bottom: 2rem;
`

const TitleStyled = styled.h3`
  margin-bottom: 1.5rem;
  font-size: 2.38rem;
  @media only screen and (${mediaQueries.tabLand}) {
    font-size: 2.2rem;
  }
  @media only screen and (${mediaQueries.phoneLand}) {
    font-size: 1.9rem;
  }
`

const TextAlignStyled = styled.p`
  text-align: center;
`

const Box = ({ title, icon, text }) => {
  const iconDefinition = findIconDefinition({ iconName: icon })

  return (
    <BoxStyled>
      <BoxBody>
        <IconStyled>
          <FontAwesomeIcon icon={iconDefinition} />
        </IconStyled>
        <TitleStyled>{title}</TitleStyled>
        <TextAlignStyled>{text}</TextAlignStyled>
      </BoxBody>
    </BoxStyled>
  )
}

export default Box