import React from "react"
import SectionTitle from "./sectionTitle"
import Box from "./box"
import { useTranslation } from "gatsby-plugin-react-i18next"
import styled from "styled-components"

const SectionStyled = styled.div`
  grid-column: center-start / center-end;
`

const BoxesStyled = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 5rem;
`
const Services = () => {
  const { t } = useTranslation()

  const services = [
    {
      title: t("services.projet.title"),
      icon: "laptop",
      text: t("services.projet.text")
    },
    {
      title: t("services.methodologie.title"),
      icon: "retweet",
      text: t("services.methodologie.text")
    },
    {
      title: t("services.solution.title"),
      icon: "ruler-combined",
      text: t("services.solution.text")
    },
    {
      title: t("services.referencement.title"),
      icon: "search",
      text: t("services.referencement.text")
    },
    {
      title: t("services.distance.title"),
      icon: "globe",
      text: t("services.distance.text")
    },
    {
      title: t("services.suivi.title"),
      icon: "hands-helping",
      text: t("services.suivi.text")
    }
  ]

  const servicesBox = services.map((data) => {
    return (<Box key={data.icon} {...data} />)
  })

  return (
    <SectionStyled>
      <SectionTitle title={t("services.title")} subTitle={t("services.subTitle")} />
      <BoxesStyled className="services-boxes">
        {servicesBox}
      </BoxesStyled>
    </SectionStyled>
  )
}

export default Services