import React from "react"
import SectionTitle from "./sectionTitle"
import Testimonial from "./testimonial"
import Slider from "react-slick"
import { useTranslation } from "gatsby-plugin-react-i18next"
import styled from "styled-components"

const Section = styled.section`
  grid-column: center-start / center-end;
  grid-row: 7 / 7;
  z-index: 50;
  padding: 5rem 0 5rem 0;
`

const SectionBackground = styled.section`
  grid-column: full-start / full-end;
  grid-row: 7 / 7;
  background-color: ${props => props.theme.color.lightGrey};
`

const Client = () => {

  const { t } = useTranslation()
  const clientItems = [
    {
      id: 1,
      image: "sara_salmi.jpg",
      name: t("client.client1.name"),
      job: t("client.client1.job"),
      text: t("client.client1.text")
    },
    {
      id: 2,
      image: "bcer.jpg",
      name: t("client.client2.name"),
      job: t("client.client2.job"),
      text: t("client.client2.text")
    }]

  const clientItemsRender = clientItems.map((data) => (
    <Testimonial key={data.id} {...data} />
  ))

  const settings = {
    dots: false,
    arrows: false,
    autoplay: true,
    infinite: true,
    speed: 500,
    autoplaySpeed: 2000,
    slidesToShow: 2,
    slidesToScroll: 1,
    className: "testimonial-carrousel",
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  }

  return (
    <>
      <Section>
        <SectionTitle title={t("client.title")}
                      subTitle={t("client.subTitle")} />
        <Slider {...settings}>
          {clientItemsRender}
        </Slider>
      </Section>
      <SectionBackground></SectionBackground>
    </>
  )
}

export default Client