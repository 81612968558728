import React from "react"
import styled from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowDown } from "@fortawesome/free-solid-svg-icons"
import ScrollTo from "../../api/scrollTo"
import { useTranslation } from "gatsby-plugin-react-i18next"

const DownScrollStyled = styled(ScrollTo)`
  border: none;
  font-size: 1.6rem;
  border-radius: 0.5rem;
  bottom: 2.5rem;
  left: 50%;
  z-index: 15;
  width: 5.6rem;
  height: 5.6rem;
  line-height: 5.6rem;
  transform: translateX(-50%);
  cursor: pointer;
  color: ${props => props.theme.color.white};
  background: ${props => props.theme.color.primary};
  position: absolute;
  text-align: center;
`

const DownScroll = ({ className }) => {
  const { t } = useTranslation()
  return (
    <DownScrollStyled id="hero-next-section" target={"about"}
              title={t("hero.a.down.label")} className={className}>
      <FontAwesomeIcon icon={faArrowDown} />
    </DownScrollStyled>
  )
}


export default DownScroll