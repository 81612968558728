import React from "react"
import SectionTitle from "./sectionTitle"
import { Trans, useTranslation } from "gatsby-plugin-react-i18next"
import styled from "styled-components"

const Section = styled.section`
  grid-column: center-start / center-end;
  text-align: center;
`

const Text = styled.p`
  font-size: 1.8rem;
`

const Commitment = () => {

  const { t } = useTranslation()

  return (
    <Section className="section-commitment" id="commitment">
      <SectionTitle title={t("commitment.title")}
                    subTitle={t("commitment.subTitle")} />
      <Text className="commitment-text">
        <Trans i18nKey="commitment.text">
          <strong className="text__primary"></strong><strong className="text__primary"></strong>
        </Trans>
      </Text>
    </Section>
  )
}

export default Commitment