import React from "react"
import Img from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"
import { Trans } from "gatsby-plugin-react-i18next"
import UnderlineImage from "./underlineImage"
import styled from "styled-components"
import { mediaQueries } from "../style/mediaQueries"

const Section = styled.section`
  grid-column: center-start / center-end;
  display: grid;
  grid-column-gap: 3rem;
  grid-row-gap: 7rem;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;

  @media only screen and (${mediaQueries.tabPort}) {
    grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  }
`

const Title = styled.h2`
  margin-bottom: 2rem;
  font-size: 4rem;
  text-transform: capitalize;
  @media only screen and (${mediaQueries.tabLand}) {
    font-size: 3.6rem;
  }
  @media only screen and (${mediaQueries.phoneLand}) {
    font-size: 3rem;
  }
  @media only screen and (${mediaQueries.phonePort}) {
    font-size: 2.9rem;
  }
`

const SubTitle = styled.strong`
  font-weight: 800;
  color: ${props => props.theme.color.primary};
`

const Text = styled.p`
  margin-bottom: 1.5rem;
`

const About = () => {

  const data = useStaticQuery(graphql`
      query {
          aboutPicture: file(relativePath: { eq: "about2.jpg" }) {
              childImageSharp {
                  fluid(quality: 90, maxWidth: 520, maxHeight: 520) {
                      ...GatsbyImageSharpFluid_withWebp
                  }
              }
          }
      }
  `)

  return (
    <Section id="about">
      <UnderlineImage>
        <Img fluid={data.aboutPicture.childImageSharp.fluid}
             alt="Photo de Roueche Sébastien"
             title="Roueche Sébastien" />
      </UnderlineImage>
      <div>
        <Title >
          <SubTitle>Sébastien Roueche </SubTitle>
          <Trans>about.founder</Trans>
        </Title>
        <Text >
          <Trans i18nKey="about.text.1">.<strong className="text__primary"></strong></Trans>
        </Text>
        <Text>
          {/*garder le point avant le strong, bug avec i18n sinon il ne prends pas en compte Text__primary*/}
          <Trans i18nKey="about.text.2">.<strong className="text__primary"></strong></Trans>
        </Text>
        <Text>
          <Trans i18nKey="about.text.3">.<strong className="text__primary"></strong></Trans>
        </Text>
      </div>
    </Section>
  )
}

export default About