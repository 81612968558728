import React, { useEffect } from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import setViewportHeight from "../api/setViewportHeight"
import { useTranslation } from "gatsby-plugin-react-i18next"
import ScrollTo from "../api/scrollTo"
import styled from "styled-components"
import { mediaQueries } from "../style/mediaQueries"
import DownScroll from "./button/DownScroll"

const HeroBackground = styled(Img)`
  grid-column: full-start / full-end;
  grid-row: 1 / 1;
  z-index: -1000;
`

const Section = styled.section`
  display: flex;
  align-items: center;
  grid-column: center-start / center-end;
  grid-row: 1 / 1;
  z-index: 160;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -4;
    background: ${props => props.theme.color.backgroundDark};
    opacity: 0.5;
  }
`

const HeroDetailContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
`

const HeroDetailInner = styled.div`
  width: 80%;
  @media only screen and (${mediaQueries.tabLand}) {
    width: 90%;
  }

  @media only screen and (${mediaQueries.phonePort}) {
    width: 95%;
  }
`

const Title = styled.h1`
  font-weight: ${props => props.theme.weight.bold};
  color: ${props => props.theme.color.primary};

  @media only screen and (${mediaQueries.phonePort}) {
    font-size: 3.8rem;
  }
`

const HeroTyped = styled.h2`
  margin-bottom: 2rem;
  display: block;
  font-family: ${props => props.theme.fonts.heading};
  font-weight: ${props => props.theme.weight.bold};
  line-height: 1.5;
  color: ${props => props.theme.color.white};
  font-size: 5.3rem;

  @media only screen and (${mediaQueries.tabLand}) {
    font-size: 4.5rem;
  }

  @media only screen and (${mediaQueries.tabPort}) {
    font-size: 4rem;
  }

  @media only screen and (${mediaQueries.phonePort}) {
    font-size: 3rem;
  }
`

const HeroText = styled.p`
  font-size: 1.76rem;
  font-style: italic;
  margin-bottom: 3rem;
  color: ${props => props.theme.color.white};
  opacity: 0.8;
`

const Hero = () => {
  //Gère le bug quand on utilise une taille 100vh, le header du mobile n'est pas pris en compte
  useEffect(() => {
    setViewportHeight()
    window.addEventListener("pageshow", () => setViewportHeight())
    window.addEventListener("orientationchange", () => setViewportHeight())
    return () => {
      window.removeEventListener("pageshow", () => setViewportHeight())
      window.removeEventListener("orientationchange", () => setViewportHeight())
    }
  }, [])

  const { t } = useTranslation()
  const data = useStaticQuery(graphql`
      query {
          bgHero: file(relativePath: { eq: "bg_hero_2.jpg" }) {
              childImageSharp {
                  fluid(quality: 90, maxWidth: 1920) {
                      ...GatsbyImageSharpFluid_withWebp_tracedSVG
                  }
              }
          }
      }
  `)

  return (
    <>
      <HeroBackground fluid={data.bgHero.childImageSharp.fluid}
                      alt="Image de fond de la partie hero"
                      className="hero-bg" />

      <Section id="hero-section">
        <HeroDetailContainer>
          <HeroDetailInner>
            <Title>Teicno</Title>
            <HeroTyped>{t("hero.typed.1")}</HeroTyped>
            <HeroText>{t("hero.introduction")}</HeroText>
            <div className="btn-group">
              <ScrollTo id="hero-contact-us" target={"contact"}
                        className={"banner-button default-button"}>
                {t("common.btn.contacter")}
              </ScrollTo>
            </div>
          </HeroDetailInner>
        </HeroDetailContainer>
        <DownScroll/>
      </Section>
    </>
  )
}

export default Hero
