import React from "react"
import Img from "gatsby-image"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faStar } from "@fortawesome/free-solid-svg-icons"
import { graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"
import { mediaQueries } from "../style/mediaQueries"

const TestimonialItem = styled.div`
  border-radius: 5px;
  padding: 30px;
  background: ${props => props.theme.color.white};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 15px;
  height: 36rem;

  @media only screen and (${mediaQueries.tabLand}) {
    height: 39rem;
  }

  @media only screen and (${mediaQueries.tabPort}) {
    height: auto;
  }
`

const ImageContainer = styled.div`
  margin-bottom: 2rem;

  img {
    border-radius: 5px;
    box-shadow: 0 2.3rem 4.9rem 0 ${props => props.theme.color.shadow};
  }
`

const DetailContainer = styled.div`
  margin-bottom: 15px;

  > h3 {
    display: block;
    text-transform: capitalize;
    margin-bottom: 10px;
    font-size: 2.38rem;
    @media only screen and (${mediaQueries.tabLand}) {
      font-size: 2.2rem;
    }
    @media only screen and (${mediaQueries.phoneLand}) {
      font-size: 1.9rem;
    }
  }

  > span {
    color: ${props => props.theme.color.primary};
    font-weight: ${props => props.theme.weight.robotoSemiBold};
  }
`
const BlockQuote = styled.blockquote`
  margin: 0 0 1.5rem 0;

  > q {
    font-style: italic;
  }
`

const Rating = styled.div`
  font-size: 1.6rem;
  color: ${props => props.theme.color.primary};

  i {
    margin-right: 3px;
    &:last-child {
      margin-right: 0;
    }
  }
`

const Testimonial = ({ image, name, job, text }) => {

  const images = useStaticQuery(graphql`
      query {
          projects: allFile(filter: {relativeDirectory: {eq: "client"}}) {
              edges {
                  node {
                      childImageSharp {
                          fixed(quality: 90, width: 120, height: 120) {
                              ...GatsbyImageSharpFixed_withWebp,
                              originalName
                          }
                      }
                  }
              }
          }
      }
  `)

  const client = images.projects.edges
    .find(project => project.node.childImageSharp.fixed.originalName === image)

  return (
    <TestimonialItem className="testimonial-item">
      <ImageContainer className="testimonial-img">
        <Img fixed={client.node.childImageSharp.fixed} alt={"photo de " + name} title={name} />
      </ImageContainer>
      <DetailContainer className="testimonial-details">
        <h3>{name}</h3>
        <span>{job}</span>
      </DetailContainer>
      <BlockQuote className="testimonial-text">
        <q> {text} </q>
      </BlockQuote>
      <Rating className="testimonial-rating">
        <FontAwesomeIcon icon={faStar} />
        <FontAwesomeIcon icon={faStar} />
        <FontAwesomeIcon icon={faStar} />
        <FontAwesomeIcon icon={faStar} />
        <FontAwesomeIcon icon={faStar} />
      </Rating>
    </TestimonialItem>
  )
}

export default Testimonial